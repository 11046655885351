import { twMerge } from 'tailwind-merge';

interface LoaderProps {
    className?: string;
}
const Loader = ({ className }: LoaderProps) => (
    <div data-testid="loader" className={twMerge('flex grow items-center justify-center', className)}>
        <div className="dbl-spinner" />
        <div className="dbl-spinner dbl-spinner--2 animate-spin" />
    </div>
);

export default Loader;
