import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import getEnvVariables from './utils/env/env';

const ENV = getEnvVariables();

interface GraphQLProviderProps {
    children: ReactNode;
}

export const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
    const [accessToken, setAccessToken] = useState('');

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    useEffect(() => {
        async function getAndSetToken() {
            try {
                const token = await getAccessTokenSilently();
                setAccessToken(token);
            } catch {
                setAccessToken('');
            }
        }
        if (isAuthenticated) {
            getAndSetToken();
        }
    }, [getAccessTokenSilently, isAuthenticated]);

    const client = useMemo(
        () =>
            new ApolloClient({
                uri: ENV.GRAPHQL_GATEWAY_URL,
                cache: new InMemoryCache(),
                headers: {
                    Authorization: accessToken ? `Bearer ${accessToken}` : '',
                },
            }),
        [accessToken]
    );

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
