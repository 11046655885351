import { StyledEngineProvider, ThemeProvider, createTheme } from '@web-chapter/ui';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import AuthProvider from './AuthProvider';
import { GraphQLProvider } from './GraphQLProvider';
import NotificationCenter from './features/notifications/NotificationCenter/NotificationCenter';
import './index.css';

const rootElement = document.getElementById('root');

const theme = createTheme({
    components: {
        MuiModal: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiPopover: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiPopper: {
            defaultProps: {
                container: rootElement,
            },
        },
    },
    typography: {
        fontFamily: 'Inter',
    },
});

createRoot(rootElement as HTMLElement).render(
    <StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <GraphQLProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <NotificationCenter />
                            <App />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </GraphQLProvider>
            </AuthProvider>
        </BrowserRouter>
    </StrictMode>
);
