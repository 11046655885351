import AuthPage from 'src/components/AuthPage/AuthPage';

const TermsOfUse = () => {
    return (
        <AuthPage className="tablet-p:w-[44rem]">
            <div className="px-16 pb-16 pt-12">
                <h1 className="typography-display-m text-center pb-8">Terms of Use</h1>

                <p className="typography-body-l">Waiting for copy ...</p>
            </div>
        </AuthPage>
    );
};

export default TermsOfUse;
