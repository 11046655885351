import { Snackbar as MuiSnackbar, Slide, SlideProps } from '@web-chapter/ui';
import { CSSProperties } from 'react';

import Alert, { AlertProps } from '../Alert/Alert';

interface SnackbarProps {
    alertProps: AlertProps;
    className?: string;
    open?: boolean;
    styles?: CSSProperties;
}

const TransitionLeft = (props: SlideProps) => {
    return <Slide {...props} direction="left" />;
};

const Snackbar = ({ alertProps, className, open, styles }: SnackbarProps) => {
    const { message, severity, timestamp, title, onClose } = alertProps;

    return (
        <MuiSnackbar
            style={styles}
            TransitionComponent={TransitionLeft}
            classes={{
                root: `w-[306px] ${className} z-[99999]`,
            }}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            open={open}
            autoHideDuration={3000}
            onClose={onClose}
        >
            <Alert title={title} severity={severity} message={message} timestamp={timestamp} onClose={onClose} />
        </MuiSnackbar>
    );
};

export default Snackbar;
