import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { ReactElement } from 'react';
import useRedirect from 'src/hooks/useRedirect';

import getEnvVariables from './utils/env/env';

interface AuthProviderProps {
    children: ReactElement;
}
const ENV = getEnvVariables();

const AuthProvider = ({ children }: AuthProviderProps) => {
    const { navigateTo } = useRedirect();

    const onRedirectCallback = (appState: AppState | undefined) => {
        navigateTo(appState?.returnTo ?? '/');
    };

    if (!ENV) {
        return <div>Environment variables missing</div>;
    }

    return (
        <Auth0Provider
            domain={ENV.AUTH0_DOMAIN}
            clientId={ENV.AUTH0_CLIENT_ID}
            authorizationParams={{
                audience: ENV.AUTH0_AUDIENCE,
                redirect_uri: ENV.AUTH0_REDIRECT_URI,
                scope: 'offline_access',
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    );
};

export default AuthProvider;
