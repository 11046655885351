import { AlertColor, Alert as MuiAlert } from '@web-chapter/ui';
import classNames from 'classnames';
import { ForwardedRef, ReactNode, forwardRef } from 'react';

import Icon from '../Icon/Icon';

export interface AlertProps {
    action?: ReactNode;
    className?: string;
    message?: string | ReactNode;
    severity?: AlertColor;
    timestamp?: string;
    title?: string;
    variant?: 'filled' | 'outlined' | 'standard';
    onClose?: () => void;
}

const Alert = forwardRef(
    (
        {
            action,
            className = '',
            message,
            severity = 'info',
            timestamp,
            title,
            variant = 'filled',
            onClose = () => {},
        }: AlertProps,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        const classes = classNames(
            'w-full border-l-4 bg-panel-active text-[0.56rem] leading-3',
            {
                'border-l-info': severity === 'info',
                'border-l-warning': severity === 'warning',
                'border-l-error': severity === 'error',
                'border-l-success': severity === 'success',
            },
            className
        );

        const iconClasses = classNames('w-16 justify-center m-0 p-0 pt-1', {
            'text-info': severity === 'info',
            'text-warning': severity === 'warning',
            'text-error': severity === 'error',
            'text-success': severity === 'success',
        });

        return (
            <MuiAlert
                severity={severity}
                action={action}
                ref={ref}
                variant={variant}
                className={classes}
                onClose={onClose}
                iconMapping={{
                    success: <Icon width="1em" name="CheckCircleOutline" className="fill-success" />,
                    info: <Icon width="1em" name="Info" className="fill-info" />,
                    warning: <Icon width="1em" name="ErrorOutline" className="fill-warning" />,
                    error: <Icon width="1em" name="ErrorOutline" className="fill-error" />,
                }}
                classes={{
                    message: 'p-0',
                    action: 'w-6 h-6 scale-75 p-0 m-0 ml-auto',
                    root: 'p-0 m-0 py-1.5 px-2 pl-0',
                    icon: iconClasses,
                }}
            >
                <div className="flex h-full flex-col gap-2.5 justify-between py-1">
                    <div className="flex flex-col gap-1">
                        {title && <div className="text-[14px] leading-[21px] capitalize">{title}</div>}
                        {message && <span className="overflow-hidden font-normal text-ellipsis">{message}</span>}
                    </div>
                    {timestamp && <span className="text-white/60 justify-self-end">{timestamp}</span>}
                </div>
            </MuiAlert>
        );
    }
);

Alert.displayName = 'Alert';

export default Alert;
