import { Outlet } from 'react-router-dom';
import useOauth2 from 'src/hooks/useOauth2';

const ProtectedRoutes = () => {
    const { isAuthenticated, loginWithRedirect } = useOauth2();

    if (!isAuthenticated) {
        loginWithRedirect();
        return null;
    }
    return <Outlet />;
};

export default ProtectedRoutes;
