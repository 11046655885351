import classNames from 'classnames';
import Logo from 'src/components/Logo/Logo';
import useOauth2 from 'src/hooks/useOauth2';
import useRedirect from 'src/hooks/useRedirect';
import getEnvVariables from 'src/utils/env/env';

import Button from '../Button/Button';

const ENV = getEnvVariables();

const Header = () => {
    const { logout, isAuthenticated } = useOauth2();

    const { locationReplaceTo, navigateTo } = useRedirect();

    return (
        <div className={classNames('flex w-full p-4 border-b border-b-white/10')}>
            <div
                className={classNames('flex-1 flex', {
                    'justify-end': isAuthenticated,
                    'justify-center': !isAuthenticated,
                })}
            >
                <Logo height="2.5rem" className="cursor-pointer" onClick={() => locationReplaceTo(ENV.BASE_URL)} />
            </div>
            {isAuthenticated && (
                <div className="flex gap-2 justify-end relative z-10 flex-1">
                    <Button color="light" text="Sign out" onClick={logout} variant="outlined" />
                    <Button
                        color="light"
                        text="Invite"
                        onClick={() => navigateTo('/invitations/send')}
                        variant="outlined"
                    />
                </div>
            )}
        </div>
    );
};

export default Header;
