import { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

interface AuthPageProps {
    children: ReactElement;
    Icon?: ReactElement;
    title?: string;
    subTitle?: ReactElement | string;
    className?: string;
}

const AuthPage = ({ children, Icon, title, subTitle, className = '' }: AuthPageProps) => {
    return (
        <div
            className={twMerge(
                'tablet-p:px-0 tablet-p:w-[25rem] mx-auto p-8 max-w-[22.75rem] tablet-p:max-w-full',
                className
            )}
        >
            {title && (
                <div className="flex flex-col gap-2 tablet-p:gap-4 items-center mb-4 tablet-p:mb-8">
                    {Icon}
                    <h1 className="typography-display-m text-light-emphasis text-center">{title}</h1>
                </div>
            )}
            {subTitle && (
                <div className="typography-body-s text-light-medium text-center mb-4 tablet-p:mb-5">{subTitle}</div>
            )}
            {children}
        </div>
    );
};

export default AuthPage;
