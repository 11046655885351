import { SVGProps } from 'react';

const SvgLinkOff = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
        <g clipPath="url(#clip0_981_3389)">
            <mask
                id="mask0_981_3389"
                width={64}
                height={64}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha',
                }}
            >
                <path d="m38.373 29.333 4.293 4.294v-4.294h-4.293Zm6.96-10.666H34.666v5.066h10.667c4.56 0 8.267 3.707 8.267 8.267a8.243 8.243 0 0 1-4.987 7.573l3.733 3.733A13.295 13.295 0 0 0 58.666 32c0-7.36-5.973-13.334-13.333-13.334Zm-40-7.28 8.293 8.293A13.31 13.31 0 0 0 5.333 32c0 7.36 5.973 13.333 13.333 13.333h10.667v-5.066H18.666c-4.56 0-8.266-3.707-8.266-8.267 0-4.24 3.226-7.733 7.36-8.187l5.52 5.52h-1.947v5.334h7.28l6.053 6.053v4.613h4.614l10.693 10.694 3.76-3.76-44.64-44.64-3.76 3.76Z" />
            </mask>
            <g mask="url(#mask0_981_3389)">
                <path d="M0 0h64v64H0z" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_981_3389">
                <path d="M0 0h64v64H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgLinkOff;
