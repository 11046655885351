import { Outlet } from 'react-router-dom';

import Header from './components/Header/Header';

const Layout = () => {
    return (
        <div className="flex h-full flex-col">
            <Header />
            <div className="flex justify-center h-full">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
