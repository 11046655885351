import { SVGProps, useMemo } from 'react';
import { RequireAtLeastOne } from 'src/utils/types/types';

import * as icons from './icons';
import { IconName } from './types';

interface IconPropsBase extends SVGProps<SVGSVGElement> {
    name: IconName;
}

export type IconProps = IconPropsBase & RequireAtLeastOne<IconPropsBase, 'height' | 'width'>;

const Icon = (props: IconProps) => {
    const { name, className = '' } = props;
    const IconComponent = useMemo(() => icons?.[name], [name]);

    if (!IconComponent) {
        return null;
    }

    return <IconComponent className={`inline-block ${className}`} data-testid={`${name}-icon`} {...props} />;
};

export default Icon;
