import { Button as MuiButton } from '@web-chapter/ui';
import classNames from 'classnames';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
    text?: string;
    children?: ReactNode;
    variant?: ButtonVariant;
    href?: string;
    fullWidth?: boolean;
    color?: 'primary' | 'light' | 'error' | 'secondary';
    inverted?: boolean;
    disabled?: boolean;
}

const Button = ({
    text,
    children,
    className,
    href,
    fullWidth = false,
    variant = 'contained',
    color = 'primary',
    inverted,
    disabled,
    ...props
}: ButtonProps) => {
    const containedClasses = classNames(
        `bg-${color} text-black/80`,
        `disabled:bg-${color}-disabled disabled:text-black/40`,
        `hover:bg-${color}-hover hover:text-black`
    );
    const outlinedClasses = classNames(
        `border border-solid border-${color} text-${color}`,
        `disabled:border-${color}-disabled disabled:text-${color}-disabled`,
        `hover:border-${color}-hover hover:text-${color}-hover hover:bg-${color}/20`
    );
    const ghostClasses = classNames(
        `text-${color}`,
        `disabled:text-${color}-disabled`,
        `hover:text-${color}-hover hover:bg-${color}/20`
    );
    const buttonClasses = classNames(
        'py-2.5 px-4 text-center rounded typography-button-base mobile:text-[14px]/[20px] transition normal-case',
        {
            [containedClasses]: variant === 'contained',
            [outlinedClasses]: variant === 'outlined',
            [ghostClasses]: variant === 'ghost',
            'w-full': fullWidth,
            'pointer-events-none': disabled,
        }
    );

    return (
        <MuiButton type="button" className={twMerge(buttonClasses, className)} disabled={disabled} {...props}>
            {children || text}
        </MuiButton>
    );
};

export type ButtonVariant = 'outlined' | 'contained' | 'ghost';

export default Button;
