import { useCallback } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

const useRedirect = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const navigateTo = useCallback(
        <T>(path = '/', params?: T) => {
            const basePath = path.charAt(0) === '/' ? '' : `${pathname.substring(0, pathname.lastIndexOf('/'))}/`;
            const parsedPath = `${basePath}${path}`;

            if (params) {
                return navigate({
                    pathname: parsedPath,
                    search: createSearchParams(params).toString(),
                });
            }
            return navigate(parsedPath);
        },
        [navigate, pathname]
    );

    const navigateToHome = () => navigateTo();

    const locationReplaceTo = useCallback((path = '/') => {
        return window.location.replace(path);
    }, []);

    const openNewWindowTo = useCallback((path = '/') => {
        return window.open(path, '_blank');
    }, []);

    return { locationReplaceTo, navigateTo, navigateToHome, openNewWindowTo };
};

export default useRedirect;
