import { AppState, useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import getEnvVariables from 'src/utils/env/env';

export interface Claims {
    terra_quantum_user_id: string;
}

const SCOPES = 'openid offline_access';
const CONNECTION = 'Username-Password-Authentication';
const ENV = getEnvVariables();
export default function useOauth2() {
    const auth0 = useAuth0();
    const location = useLocation();

    const loginWithRedirect = useCallback(
        () =>
            auth0.loginWithRedirect({
                appState: {
                    connection: CONNECTION,
                    returnTo: location.pathname,
                    scope: SCOPES,
                } as AppState,
            }),
        [auth0, location]
    );

    const logout = useCallback(
        () =>
            auth0.logout({
                logoutParams: {
                    returnTo: ENV.AUTH0_REDIRECT_URI,
                },
            }),
        [auth0]
    );

    const { isAuthenticated, isLoading, user, getAccessTokenSilently, getIdTokenClaims } = auth0;

    const [claims, setClaims] = useState<Claims>({ terra_quantum_user_id: '' });
    useEffect(() => {
        async function getClaims() {
            const result = await getIdTokenClaims();

            setClaims({ terra_quantum_user_id: result?.terra_quantum_user_id });
        }

        if (isAuthenticated) {
            getClaims();
        }
    }, [isAuthenticated, getIdTokenClaims]);

    return {
        claims,
        user,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
    };
}
