import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import useOauth2 from 'src/hooks/useOauth2';

import Layout from './Layout';
import Loader from './components/Loader/Loader';
import ProtectedRoutes from './components/ProtectedRoutes/ProtectedRoutes';
import PrivacyPolicy from './features/auth0/pages/privacy-policy/PrivacyPolicy';
import SendFeedback from './features/auth0/pages/send-feedback/SendFeedback';
import TermsOfUse from './features/auth0/pages/terms-of-use/TermsOfUse';

const PlatformRouter = lazy(() => import('./features/platform/PlatformRouter'));
const LandingPage = lazy(() => import('./features/landing-page/pages/LandingPage'));
const SignInPage = lazy(() => import('./features/auth0/pages/sign-in/SignInPage/SignInPage'));
const WaitlistSignupContainer = lazy(
    () => import('./features/waitlist-signup/pages/WaitlistSignup/WaitlistSignupContainer')
);
const InvitationsRouter = lazy(() => import('./features/invitations/InvitationsRouter'));
const ForgotPasswordRouter = lazy(() => import('./features/auth0/pages/forgot-password/ForgotPasswordRouter'));
const ResetPasswordRouter = lazy(() => import('./features/auth0/pages/reset-password/ResetPasswordRouter'));

const AdminRouter = lazy(() => import('./features/admin/AdminRouter'));

const RootRouter = () => {
    const { isAuthenticated, isLoading } = useOauth2();
    if (isLoading) {
        return <Loader />;
    }

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path="/">
                    <Route element={<Layout />}>
                        {!isAuthenticated && (
                            <>
                                <Route index element={<LandingPage />} />
                                <Route path="terms-of-use" element={<TermsOfUse />} />
                                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                                <Route path="send-feedback" element={<SendFeedback />} />

                                <Route path="login" element={<SignInPage />} />
                                <Route path="lo/reset/*" element={<ResetPasswordRouter />} />
                            </>
                        )}
                        <Route path="waitlist-signup" element={<WaitlistSignupContainer />} />
                        <Route path="change-password/*" element={<ForgotPasswordRouter />} />
                        <Route path="invitations/*" element={<InvitationsRouter />} />
                    </Route>
                    <Route element={<ProtectedRoutes />}>
                        <Route index element={<PlatformRouter />} />
                        <Route path="admin/*" element={<AdminRouter />} />
                        <Route path="/*" element={<PlatformRouter />} />
                    </Route>
                </Route>
            </Routes>
        </Suspense>
    );
};

export default RootRouter;
