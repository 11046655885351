import { AlertColor } from '@web-chapter/ui';
import moment from 'moment-mini';
import { ReactNode, useEffect, useState } from 'react';
import Snackbar from 'src/components/Snackbar/Snackbar';
import { v4 as uuidv4 } from 'uuid';

const NOTIFICATION_EVENT = 'onTestNotification';

interface Notification {
    id: string;
    message?: string | ReactNode;
    open?: boolean;
    timestamp?: string;
    title?: string;
    type: AlertColor;
}

export const pushNotification = (props: Omit<Notification, 'id'>, displayTimestamp = true) => {
    const id = uuidv4();
    const timeNow = displayTimestamp ? moment().calendar() : undefined;
    const event = new CustomEvent(NOTIFICATION_EVENT, {
        detail: { id, open: true, timestamp: timeNow, ...props },
    });
    document.dispatchEvent(event);
};

const NotificationCenter = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const listener = (e: CustomEvent) => {
        setNotifications((prev) => [...prev, e.detail]);
    };

    const handleCloseNotification = (id: string) => {
        setNotifications((prev) =>
            prev.map((notification) => (notification.id === id ? { ...notification, open: false } : notification))
        );
    };
    useEffect(() => {
        document.addEventListener(NOTIFICATION_EVENT, listener);

        return () => {
            document.removeEventListener(NOTIFICATION_EVENT, listener);
        };
    }, []);

    if (notifications.length < 1) return null;

    return (
        <>
            {notifications.map((notification) => (
                <Snackbar
                    key={notification.id}
                    alertProps={{
                        message: notification.message,
                        severity: notification.type,
                        timestamp: notification.timestamp,
                        title: notification.title ?? notification.type,
                        onClose: () => handleCloseNotification(notification.id),
                    }}
                    open={notification.open}
                />
            ))}
        </>
    );
};

export default NotificationCenter;
