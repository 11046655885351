interface EnvObject {
    AUTH0_DOMAIN: string;
    AUTH0_CLIENT_ID: string;
    AUTH0_AUDIENCE: string;
    AUTH0_REDIRECT_URI: string;
    BASE_URL: string;
    GRAPHQL_GATEWAY_URL: string;
}

type EnvType = 'LOCAL' | 'INT' | 'STAGING' | 'PROD';
type EnvVariablesProvider = (env: EnvType) => EnvObject;
type EnvProvider = () => EnvType | null;

const getVariablesBasedOnEnv: EnvVariablesProvider = (env) => ({
    AUTH0_REDIRECT_URI: import.meta.env[`VITE_AUTH0_REDIRECT_URI_${env}`],
    AUTH0_AUDIENCE: import.meta.env[`VITE_AUTH0_AUDIENCE_${env}`],
    AUTH0_CLIENT_ID: import.meta.env[`VITE_AUTH0_CLIENT_ID_${env}`],
    AUTH0_DOMAIN: import.meta.env[`VITE_AUTH0_DOMAIN_${env}`],
    BASE_URL: import.meta.env[`VITE_BASE_URL_${env}`],
    GRAPHQL_GATEWAY_URL: import.meta.env[`VITE_GRAPHQL_GATEWAY_URL_${env}`],
});

const LOCAL_DOMAIN_REGEX = /^(localhost|127\.0\.0\.1|tq-poc.pages.dev)(:.*|\/)*$/;
const INT_DOMAIN_REGEX = /^((web|auth)\.int\.terraquantum\.io)(:.*|\/)*$/;
const STAGING_DOMAIN_REGEX = /^((web|auth)\.staging\.terraquantum\.io)(:|\/)*$/;
const PROD_DOMAIN_REGEX = /^(terraquantum\.io)(:|\/)*$/;

const getEnvFromLocation: EnvProvider = () => {
    const { hostname } = window.location;

    if (hostname.match(LOCAL_DOMAIN_REGEX)) {
        return 'LOCAL';
    }

    if (hostname.match(INT_DOMAIN_REGEX)) {
        return 'INT';
    }

    if (hostname.match(STAGING_DOMAIN_REGEX)) {
        return 'STAGING';
    }

    if (hostname.match(PROD_DOMAIN_REGEX)) {
        return 'PROD';
    }

    return null;
};

const getEnvVariables = () => {
    const env = getEnvFromLocation();
    return getVariablesBasedOnEnv(env ?? 'LOCAL');
};

export default getEnvVariables;
