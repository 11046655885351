import classNames from 'classnames';
import { useMemo } from 'react';
import Icon, { IconProps } from 'src/components/Icon/Icon';

const SIZES = {
    sm: 16,
    md: 32,
    lg: 64,
};

interface LogoProps extends Omit<IconProps, 'name'> {
    variant?: 'sm' | 'md' | 'lg';
}

const Logo = ({ variant = 'lg', className, ...props }: LogoProps) => {
    const sizeValue = useMemo(() => SIZES[variant], [variant]);

    return (
        <Icon
            name="Logo"
            className={classNames('fill-white', className)}
            width={sizeValue}
            height={sizeValue}
            {...props}
        />
    );
};

export default Logo;
