import { SVGProps } from 'react';

const SvgLinkedin = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g clipPath="url(#clip0_131_9750)">
            <mask
                id="mask0_131_9750"
                width={24}
                height={25}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha',
                }}
            >
                <path d="M20.82 1.501H3.294c-.957 0-1.794.689-1.794 1.635v17.565c0 .952.837 1.8 1.794 1.8h17.521c.963 0 1.685-.854 1.685-1.8V3.136c.006-.946-.722-1.635-1.68-1.635ZM8.01 19.006H5V9.652H8.01v9.354ZM6.61 8.229h-.022c-.963 0-1.586-.716-1.586-1.613 0-.914.64-1.614 1.624-1.614.984 0 1.587.695 1.608 1.614 0 .897-.624 1.613-1.625 1.613Zm12.395 10.777h-3.009V13.89c0-1.225-.438-2.063-1.526-2.063-.832 0-1.324.563-1.543 1.11-.082.198-.104.466-.104.74v5.328H9.815V9.652h3.008v1.301c.438-.623 1.122-1.52 2.713-1.52 1.975 0 3.469 1.301 3.469 4.108v5.465Z" />
            </mask>
            <g mask="url(#mask0_131_9750)">
                <path d="M0 .001h24v24H0z" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_131_9750">
                <path d="M0 .001h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgLinkedin;
