import { SVGProps } from 'react';

const SvgCheckboxChecked = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
        <rect width={20} height={20} rx={4} />
        <g clipPath="url(#clip0_20_1628)">
            <mask
                id="mask0_20_1628"
                width={20}
                height={20}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha',
                }}
            >
                <path d="M7.5 13.475 4.025 10l-1.183 1.175L7.5 15.833l10-10-1.175-1.175L7.5 13.475Z" />
            </mask>
            <g mask="url(#mask0_20_1628)">
                <path d="M0 0h20v20H0z" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_20_1628">
                <path d="M0 0h20v20H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgCheckboxChecked;
