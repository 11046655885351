import RootRouter from './RootRouter';
import './tailwind.app.css';

const App = () => {
    return (
        <div className="flex flex-col h-auto tablet-p:h-full bg-auth-surface">
            <RootRouter />
        </div>
    );
};

export default App;
